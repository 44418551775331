import whenWindowLoaded from '@netc/when-window-loaded';

async function initialise() {
  if ('objectFit' in document.documentElement.style === false) {
    const images = document.querySelectorAll('.js-object-fit');

    if (images && images.length) {
      const { default: objectFitImages } = await import('object-fit-images');

      objectFitImages(images, { watchMQ: true });
    }
  }
}

whenWindowLoaded().then(initialise);
