import whenWindowLoaded from '@netc/when-window-loaded';

async function initialise() {
  if ('objectFit' in document.documentElement.style === false) {
    const videos = document.querySelectorAll('.js-video');

    if (videos && videos.length) {
      const { default: objectFitVideos } = await import('object-fit-videos');

      objectFitVideos(videos);
    }
  }
}

whenWindowLoaded().then(initialise);
